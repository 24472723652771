import React from 'react'
import Layout from '../../../components/Layout'

const SuccessPage = () => {
  return (
    <Layout>
      <section className='hero is-primary is-bold is-large'>
        <div className='hero-body has-text-centered'>
          <h1 className='title'>
            Success
          </h1>
        </div>
      </section>
      <div className=''>
        <iframe
          src="https://checkoutpage.co/checkout/5ea053a58cce360015d0e825/flank-website-rewrite"
          width="100%"
          height="1000"
          frameborder="0"
        ></iframe>
      </div>
    </Layout>
  )
}

export default SuccessPage
